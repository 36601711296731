import React, { useState } from "react";
import Pagination from "./Pagination";
import { generateColumns } from "../../utils/tableColumns";

interface DataTableProps {
  data: any[];
  pageSizeOptions?: number[];
  onCellClick?: (columnKey: string, row: any) => void; // Changed to columnKey
  customRenderers?: {
    [columnKey: string]: (value: any, row: any) => React.ReactNode; // Custom render function per column
  };
  headerOverrides?: { [key: string]: string }; // Optional header overrides
  clickableColumns?: string[]; // Array of column keys that should be clickable
  visibleColumns: string[]; // New prop to specify which columns should be visible
  fixedHeight?: boolean; // Optional fixed height flag
}

const DataTable: React.FC<DataTableProps> = ({
  data,
  pageSizeOptions = [5, 10, 20],
  onCellClick,
  customRenderers = {}, // Default to an empty object
  headerOverrides = {}, // Default to an empty object
  clickableColumns = [], // Default to an empty array
  visibleColumns, // New prop to specify visible columns
  fixedHeight = false, // Default to false
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set()); // Track selected row indices
  const [selectedAllRows, setSelectedAllRows] = useState(false); // Track if all checkboxes are selected

  // If data is empty, no table needs to be rendered
  if (data.length === 0)
    return <div className="mt-6 text-center text-gray-500">No data found.</div>;

  // Generate columns dynamically and reorder them
  const columns = generateColumns(data, visibleColumns, headerOverrides);

  // Pagination logic
  let paginatedData: any[] = [];

  const startIndex = (currentPage - 1) * pageSize;

  if (fixedHeight) {
    // If fixedHeight is true, do not slice data (show all data)
    paginatedData = data;
  } else {
    // Apply pagination logic when fixedHeight is false
    paginatedData = data.slice(startIndex, startIndex + pageSize);
  }

  // Handle selecting individual row checkboxes
  const handleRowCheckboxChange = (rowIndex: number) => {
    const updatedSelectedRows = new Set(selectedRows);
    if (updatedSelectedRows.has(rowIndex)) {
      updatedSelectedRows.delete(rowIndex); // Unselect
    } else {
      updatedSelectedRows.add(rowIndex); // Select
    }
    setSelectedRows(updatedSelectedRows);
  };

  // Handle selecting the header checkbox (select all rows)
  const handleHeaderCheckboxChange = () => {
    if (selectedAllRows) {
      setSelectedRows(new Set()); // Deselect all
    } else {
      const allRowIndices = paginatedData.map((_, index) => index + startIndex);
      setSelectedRows(new Set(allRowIndices)); // Select all
    }
    setSelectedAllRows(!selectedAllRows);
  };

  const handleCellClick = (key: string, row: any) => {
    if (onCellClick && clickableColumns.includes(key)) {
      onCellClick(key, row); // Trigger the click handler for specific columns
    }
  };

  // Handler to update page size from Pagination component
  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  return (
    <div className={`p-4 bg-white rounded shadow-md w-auto `}>
      {/* Table with scroll */}
      <div
        className={`overflow-x-auto ${
          pageSize > 10 ? "max-h-72 overflow-y-auto" : ""
        }${fixedHeight ? "max-h-[33rem] overflow-y-auto" : ""}`}
      >
        <table className="w-full border-collapse">
          <thead className="sticky bg-gray-200 m-0 top-0">
            <tr className=" whitespace-break-spaces">
              <th className="p-2 border">
                <div className="flex justify-center items-center">
                  <input
                    type="checkbox"
                    className="cursor-pointer w-4 h-4"
                    checked={selectedAllRows} // Controlled checkbox
                    onChange={handleHeaderCheckboxChange}
                  />
                </div>
              </th>
              {columns.map((col) => (
                <th key={col.key} className="p-2 border text-left">
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedData.length > 0 ? (
              paginatedData.map((row, rowIndex) => {
                const rowKey = row.id; // Assuming 'id' is unique, use it as the key
                const isSelected = selectedRows.has(rowIndex + startIndex); // Check if the row is selected
                return (
                  <tr
                    key={rowIndex}
                    className="hover:bg-gray-100 whitespace-break-spaces"
                  >
                    <td className="p-2 border ">
                      <div className="flex justify-center items-center">
                        <input
                          type="checkbox"
                          className="cursor-pointer w-4 h-4"
                          checked={isSelected} // Controlled checkbox for row
                          onChange={() =>
                            handleRowCheckboxChange(rowIndex + startIndex)
                          } // Update row selection
                        />
                      </div>
                    </td>
                    {columns.map((col) => {
                      const value = row[col.key];
                      const content =
                        customRenderers[col.key]?.(value, row) || value;
                      return (
                        <td
                          key={col.key}
                          className={`p-2 border ${
                            clickableColumns.includes(col.key)
                              ? "cursor-pointer"
                              : ""
                          }`}
                          onClick={() => {
                            if (clickableColumns.includes(col.key)) {
                              handleCellClick(col.key, row);
                            }
                          }}
                        >
                          {col.key === "id" ? (
                            <div className="flex justify-center items-center">
                              {content}
                            </div>
                          ) : (
                            content
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} className="text-center p-4">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Component */}
      {data.length > 0 && !fixedHeight && (
        <Pagination
          currentPage={currentPage}
          totalEntries={data.length}
          pageSizeOptions={pageSizeOptions}
          onPageChange={(page) => setCurrentPage(page)}
          onPageSizeChange={handlePageSizeChange}
        />
      )}
    </div>
  );
};

export default DataTable;
