import React from "react";

const LogoutPage: React.FC = () => {
  return (
    <div className="h-screen flex justify-center items-center bg-gray-100 pb-16">
      <div
        className="flex flex-col gap-y-7 items-center p-10 rounded-lg shadow-xl transition-all duration-300 hover:shadow-2xl"
        style={{
          background:
            "linear-gradient(135deg,rgb(78, 115, 223), rgb(0, 195, 255))",
        }}
      >
        <h2 className="text-3xl font-semibold text-[green]">
          You have successfully logged out!
        </h2>
        <span className="text-2xl text-white">
          <a href="/" className="underline hover:text-black">
            Click here
          </a>{" "}
          to login again.
        </span>
      </div>
    </div>
  );
};

export default LogoutPage;
