import React, { useEffect } from "react";
import { useBreadcrumb } from "../../contexts/BreadcrumbContext";
import AdmissionForm from "../../components/features/admission/student/AdmissionForm";

const StudentAdmissioin: React.FC = () => {
  const { setBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      { label: "Student Management" },
      { label: "Student Admission Form" }, // No path for the current page
    ]);
  }, [setBreadcrumb]);

  return (
    <div className="px-4 pb-4">
      <AdmissionForm />
    </div>
  );
};

export default StudentAdmissioin;
