import React from "react";
import { createPortal } from "react-dom";
import Button from "./Button";

interface PopupProps {
  isPopupOpen: boolean;
  title?: string;
  content: React.ReactNode;
  buttons: {
    label: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
    style?: string;
  }[];
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  divClassName?: string;
}

const Popup: React.FC<PopupProps> = ({
  isPopupOpen,
  title,
  content,
  buttons,
  onClose,
  divClassName,
}) => {
  if (!isPopupOpen) return null;

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center xl:pb-44 bg-black bg-opacity-50 backdrop-blur-sm z-[9999]">
      <div className={`rounded-lg shadow-lg p-5 relative ${divClassName}`}>
        {/* Close Button */}
        <Button
          label="&times;"
          onClick={onClose}
          className="absolute top-3 right-4 text-gray-400 hover:text-gray-600 text-2xl"
        />

        {/* Title */}
        {title && (
          <h3 className="text-xl font-semibold mb-3 text-center">{title}</h3>
        )}

        {/* Content */}
        <div className="mb-4 text-center">{content}</div>

        {/* Buttons */}
        <div className="flex justify-center gap-3">
          {buttons.map((button, index) => (
            <Button
              key={index}
              label={button.label}
              onClick={button.onClick}
              className={`px-4 py-2 rounded-md font-semibold ${
                button.style || "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            />
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Popup;
