import React from "react";
import Sidebar from "../common/Sidebar";
import Header from "../common/Header";
import { useDashboard } from "../../contexts/DashboardContext";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../common/Breadcrumb";

type DashboardLayoutProps = React.PropsWithChildren<{}>;

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const { isSidebarOpen } = useDashboard();

  const location = useLocation();

  // Only show the breadcrumb if the current route is not "/dashboard"
  const isDashboard = location.pathname !== "/dashboard";

  return (
    <div>
      <Header />
      <div className="absolute top-[64px] w-full h-screen flex bg-gray-100">
        <Sidebar />
        <div
          className={`flex-1 relative transition-all ${
            isSidebarOpen ? "ml-[192px]" : "h-screen overflow-y-auto"
          }`}
        >
          <div className="absolute w-full h-screen p-2 xl:px-4 xl:py-3 bg-gray-100">
            {isDashboard && <Breadcrumb />}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
