import React from "react";
import { useNavigate } from "react-router-dom";
import { useBreadcrumb } from "../../contexts/BreadcrumbContext";
import "../../assets/styles/breadcrumb.css";

const Breadcrumb: React.FC = () => {
  const { breadcrumb } = useBreadcrumb();
  const navigate = useNavigate();

  if (breadcrumb.length === 0) return null; // Hide breadcrumb if empty

  // Find the first breadcrumb item without a path
  const firstWithoutPathIndex = breadcrumb.findIndex((crumb) => !crumb.path);

  return (
    <div>
      {/* Render the first breadcrumb without a path as a heading */}
      {/* {firstWithoutPathIndex !== -1 && (
        <h1 className="mb-3 text-xl">
          {breadcrumb[firstWithoutPathIndex].label}
        </h1>
      )} */}

      {/* Render the breadcrumb items except the first one without a path */}
      <nav className="max-w-max pb-3">
        <ul className="breadcrumb">
          {breadcrumb
            .filter((crumb, index) => index !== firstWithoutPathIndex) // Filter out the first item without a path
            .map((crumb, index) => (
              <li
                key={index}
                onClick={() => crumb.path && navigate(crumb.path)} // Only navigate if there's a path
                className={`${crumb.path ? "cursor-pointer" : ""}`}
              >
                {crumb.path ? (
                  <button>{crumb.label}</button>
                ) : (
                  <span>{crumb.label}</span>
                )}
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default Breadcrumb;
