import React, { useState } from "react";
import { FieldConfig, RowConfig } from "../../types/FormDataTypes";
import { validateField } from "../../utils/formValidation";

interface FormTabProps {
  formData: any;
  handleNext: (data: any) => void;
  fields: FieldConfig[];
  rows: RowConfig[];
}

const FormTab: React.FC<FormTabProps> = ({
  formData,
  handleNext,
  fields,
  rows,
}) => {
  const [formState, setFormState] = useState(formData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Update handleChange to work with both input and select elements
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    // If the field is phone number, limit it to 10 digits
    if (name === "contactNumber" && value.length > 10) {
      return; // Prevent any further input if length exceeds 10
    }

    setFormState((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    const field = fields.find((f) => f.name === name);
    if (field && field.validation) {
      // Validate the field dynamically
      const error = validateField(value, field.validation);
      setErrors((prev) => ({
        ...prev,
        [name]: error || "",
      }));
    }
  };

  const handleSubmit = () => {
    handleNext(formState);
  };

  return (
    <form className="space-y-8">
      {/* Render fields dynamically */}
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className="grid gap-12 grid-cols-3">
          {row.fields.map((fieldName) => {
            const field = fields.find((f) => f.name === fieldName);
            if (!field) return null;

            return (
              <div key={fieldName} className="flex flex-col relative">
                <label className="font-admissionForm font-semibold text-gray-800 mb-2">
                  {field.label ? field.label : <br />}
                </label>
                {field.type === "select" ? (
                  <select
                    name={field.name}
                    value={formState[field.name] || ""}
                    onChange={handleChange}
                    className="input text-sm font-admissionForm px-3 py-1 bg-white border-2 border-[#45b1a8b3] rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-[#45b1a8b3] w-36"
                  >
                    <option value="">Select</option>
                    {field.options?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : field.type === "date" ? (
                  <input
                    type={field.type}
                    name={field.name}
                    value={formState[field.name] || ""}
                    onChange={handleChange}
                    className="input text-sm font-admissionForm px-3 py-1 bg-white border-2 border-[#45b1a8b3] rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-[#45b1a8b3] w-40"
                  />
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    value={formState[field.name] || ""}
                    onChange={handleChange}
                    className={`input text-sm font-admissionForm px-3 py-1 bg-white border-2 border-[#45b1a8b3] rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-[#45b1a8b3] w-64 ${
                      field.name.includes("Address") && "w-80"
                    }`}
                    placeholder={field.placeholder}
                  />
                )}

                {/* Error message positioning */}
                {errors[field.name] && (
                  <div className="absolute -bottom-6 text-red-500 text-sm mt-1">
                    {errors[field.name]}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </form>
  );
};

export default FormTab;
