import axios from "axios";
import { getAuthToken } from "../utils/loginSecureUtility";

const baseURL = process.env.REACT_APP_API_URL;

// Function to log in and get the token
export const loginUser = async (userName: string, password: string) => {
  try {
    const API_URL = baseURL + "/api/users/loginsecuser";

    // Send the userName and password as JSON in the body of the POST request
    const response = await axios.post(API_URL, {
      userName, // Use the userName entered by the user
      password, // Use the password entered by the user
    });

    // console.log("Login Response:", response.data); // Log the full response data

    // Destructure the response data
    const {
      userName: returnedUserName,
      authToken,
      tenantId,
      tenantName,
      campuses,
      menuTabs,
    } = response.data;

    // Save all the relevant data in sessionStorage
    sessionStorage.setItem("userName", returnedUserName); // Save userName
    sessionStorage.setItem("authToken", authToken);
    sessionStorage.setItem("tenantId", tenantId); // Save tenantId
    sessionStorage.setItem("tenantName", tenantName); // Save tenantName
    sessionStorage.setItem("campuses", JSON.stringify(campuses)); // Save campuses as a JSON string
    sessionStorage.setItem("menuTabs", JSON.stringify(menuTabs));

    // Set default campus ID if it's not set
    const defaultCampusId = campuses.find(
      (campus: { isDefault: string }) => campus.isDefault === "Y"
    )?.campusId;
    if (defaultCampusId) {
      sessionStorage.setItem("selectedCampusId", defaultCampusId.toString());
    }

    return response.data; // Return the complete response data
  } catch (error: any) {
    if (error.response && error.response.data) {
      console.error("Error response:", error.response.data); // Log the error response
      throw error.response.data; // Pass the error data to the caller
    }
    console.error("Unexpected error:", error); // Log unexpected errors
    throw new Error("An unexpected error occurred.");
  }
};

// Helper to get the campus ID from sessionStorage
const getCampusId = (): number => {
  const campusId = Number(sessionStorage.getItem("selectedCampusId"));
  // console.log("getCampusId: Retrieved Campus ID:", campusId); // Debugging log

  if (isNaN(campusId) || campusId <= 0) {
    throw new Error("Invalid campus ID. Please select a valid campus.");
  }

  return campusId;
};

// Fetch Students Info - Only call this after the user is logged in
export const studentsInfo = async () => {
  try {
    const authToken = getAuthToken(); // Retrieve the token using the utility function
    const campusId = getCampusId(); // Dynamically get the current campus ID from sessionStorage

    // console.log("Auth Token:", authToken);
    // console.log("Selected Campus ID:", campusId);

    if (!authToken) {
      throw new Error("User not authenticated. Please log in.");
    }

    const apiUrl = baseURL + "/api/students/getAllStudents";

    // Include the authToken in the Authorization header and campus ID in the X-CAMPUS-ID header
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Attach token as Bearer token
        "X-CAMPUS-ID": campusId,
      },
    });

    return response.data; // Return the students data
  } catch (error: any) {
    if (error.response && error.response.data) {
      const errorData = error.response.data;
      if (
        errorData.error === "TokenSignatureError" ||
        errorData.error === "TokenExpiredError" ||
        errorData.error === "TokenUnknownError"
      ) {
        sessionStorage.clear();
        window.location.href = "/session_expired"; // Redirect to the login page
        return; // Early exit
      }
      throw errorData;
    }
    throw new Error("An error occurred while fetching the students data.");
  }
};

// Fetch Home Search Results - Requires authToken
export const homeSearchResults = async (params: {
  search: string;
  searchType: string;
}) => {
  try {
    const authToken = getAuthToken(); // Retrieve the token using the utility function

    if (!authToken) {
      throw new Error("User not authenticated. Please log in.");
    }

    const campusId = getCampusId(); // Dynamically get the current campus ID from sessionStorage

    const apiUrl = baseURL + "/api/home/flexiSearch";

    // Include the authToken in the Authorization header
    const response = await axios.post(apiUrl, params, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Attach token as Bearer token
        "X-CAMPUS-ID": campusId,
        "Content-Type": "application/json", // Ensure the server expects JSON content
      },
    });

    return response.data; // Return the search results data
  } catch (error: any) {
    if (error.response && error.response.data) {
      const errorData = error.response.data;
      if (
        errorData.error === "TokenSignatureError" ||
        errorData.error === "TokenExpiredError" ||
        errorData.error === "TokenUnknownError"
      ) {
        sessionStorage.clear();
        window.location.href = "/session_expired"; // Redirect to the login page
        return;
      }
      throw errorData;
    }
    throw new Error("An error occurred while fetching search results.");
  }
};

// Fetch Student details
export const studentDetails = async (studentId: number) => {
  try {
    const authToken = getAuthToken(); // Retrieve the token using the utility function

    if (!authToken) {
      throw new Error("User not authenticated. Please log in.");
    }

    const campusId = getCampusId(); // Dynamically get the current campus ID from sessionStorage

    const apiUrl = baseURL + `/api/students/getStudent/${studentId}`;

    // Include the authToken in the Authorization header
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`, // Attach token as Bearer token
        "X-CAMPUS-ID": campusId,
      },
    });

    return response.data; // Return the student details
  } catch (error: any) {
    if (error.response && error.response.data) {
      const errorData = error.response.data;
      if (
        errorData.error === "TokenSignatureError" ||
        errorData.error === "TokenExpiredError" ||
        errorData.error === "TokenUnknownError"
      ) {
        sessionStorage.clear();
        window.location.href = "/session_expired"; // Redirect to the login page
        return;
      }
      throw errorData;
    }
    throw new Error("An error occurred while fetching the student details.");
  }
};
