import React, { useState } from "react";

interface PaginationProps {
  currentPage: number;
  totalEntries: number;
  onPageChange: (page: number) => void;
  pageSizeOptions: number[]; // Page size options
  onPageSizeChange: (size: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalEntries,
  onPageChange,
  pageSizeOptions,
  onPageSizeChange,
}) => {
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]); // Default to first page size option

  const totalPages = Math.ceil(totalEntries / pageSize);

  const handlePageSizeChange = (size: number) => {
    setPageSize(size); // Update local state for page size
    onPageSizeChange(size); // Notify parent (DataTable) about the page size change
  };

  return (
    <div className="flex flex-row gap-y-4 justify-between mt-4 items-center">
      {/* Page size selector and options */}
      <div className="flex items-center gap-4 mt-0">
        <select
          className="border p-2 rounded"
          value={pageSize}
          onChange={(e) => handlePageSizeChange(Number(e.target.value))}
        >
          {pageSizeOptions.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>

      <span>
        Showing {Math.min((currentPage - 1) * pageSize + 1, totalEntries)} to{" "}
        {Math.min(currentPage * pageSize, totalEntries)} of {totalEntries}{" "}
        entries
      </span>

      {/* Pagination controls */}
      <div>
        <button
          className="p-2 mx-1 border rounded disabled:opacity-50"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className="p-2 mx-1 border rounded disabled:opacity-50"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
