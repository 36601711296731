import React, { useState } from "react";
import FormTab from "../../FormTab";
import {
  FieldConfig,
  ParentInfo,
  RowConfig,
} from "../../../../types/FormDataTypes";

interface ParentInfoTabProps {
  handleNext: (data: any) => void;
  formData: ParentInfo;
}

const ParentInfoTab: React.FC<ParentInfoTabProps> = ({
  handleNext,
  formData,
}) => {
  // Define the fields configuration dynamically, including nested name fields
  const fields: FieldConfig[] = [
    {
      name: "fatherName",
      label: "Father's Name",
      type: "text",
      placeholder: "Enter father's name",
      required: true,
      validation: ["required", "isAlpha"],
    },
    {
      name: "fatherOccupation",
      label: "Father's Occupation",
      type: "text",
      placeholder: "Enter father's Occupation",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "motherName",
      label: "Mother's Name",
      type: "text",
      placeholder: "Enter middle name",
      required: true,
      validation: ["required", "isAlpha"],
    },
    {
      name: "motherOccupation",
      label: "Mother's Occupation",
      type: "text",
      placeholder: "Enter mother's Occupation",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "contactNumber",
      label: "Parent/Guardian Contact Number",
      type: "tel",
      placeholder: "Enter contact number",
      required: true,
      validation: ["required", "isPhoneNumber"],
    },
    {
      name: "email",
      label: "Parent/Guardian Email Address",
      type: "email",
      placeholder: "Enter Email Address",
      required: true,
      validation: ["required", "isEmail"],
    },
    {
      name: "relation",
      label: "Relationship to the Student",
      type: "text",
      placeholder: "Enter relation",
      required: true,
      validation: ["required", "isAlpha"],
    },
  ];

  const rows: RowConfig[] = [
    { fields: ["fatherName", "fatherOccupation"] },
    { fields: ["motherName", "motherOccupation"] },
    { fields: ["contactNumber", "email", "relation"] },
  ];

  return (
    <FormTab
      formData={formData}
      handleNext={handleNext}
      fields={fields}
      rows={rows}
    />
  );
};

export default ParentInfoTab;
