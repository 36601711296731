import React, { useState } from "react";

interface Tab {
  name: string;
  content: string; // You can customize this to be more complex if necessary
}

interface TabsProps {
  tabsData: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabsData }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <div
      className={`w-full`} // Add right border when last tab is active
    >
      {/* Tab Names */}
      <div className={`flex w-full`}>
        {tabsData.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(index)}
            className={`flex-1 py-2 font-semibold transition-all duration-300 border-t-4 ${
              activeTabIndex === index
                ? "border-t-[#e8c2ca] bg-white text-[#b392ac]"
                : "border-t-transparent bg-[#e8c2ca] hover:bg-[#b392ac]"
            } ${index !== 0 && "border-l-2 border-white"} ${
              index === 0 && "rounded-ss-md"
            } ${index === tabsData.length - 1 && "rounded-se-md"} ${
              activeTabIndex === index &&
              index === 0 &&
              "border-l-4 border-l-[#e8c2ca]"
            } ${
              activeTabIndex === index &&
              index === tabsData.length - 1 &&
              "border-r-4 border-r-[#e8c2ca]"
            }`} // Separation lines
          >
            {tab.name}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="w-full mt-0">
        <div className="bg-white p-6 shadow-md rounded-es-md rounded-ee-md">
          <p>{tabsData[activeTabIndex].content}</p>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
