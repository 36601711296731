import React from "react";

const TokenErrorPage: React.FC = () => {
  return (
    <div className="h-screen flex justify-center items-center bg-gray-100 pb-16">
      <div
        className="flex flex-col gap-y-7 items-center p-16 mb-24 rounded-lg shadow-xl transition-all duration-300 hover:shadow-2xl"
        style={{ background: "linear-gradient(135deg, #1e3a8a, #4e73df)" }}
      >
        <h2 className="text-3xl font-semibold text-red-300">
          Your session expired. Please login again.
        </h2>
        <span className="text-2xl text-white">
          <a href="/" className="underline hover:text-[#00ffc3]">
            Click here
          </a>{" "}
          to login.
        </span>
      </div>
    </div>
  );
};

export default TokenErrorPage;
