import React, { ForwardedRef } from "react";

type ButtonProps = {
  label: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>; // Allow async functions
  className?: string; // Optional custom class for further styling
};

// Forward ref to allow passing ref when needed
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, onClick, className }, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <button onClick={onClick} className={className} ref={ref}>
        {label}
      </button>
    );
  }
);

// Set display name for better debugging
Button.displayName = "Button";

export default Button;
