import React, { useEffect, useState } from "react";
import { useStudents } from "../../contexts/StudentsContext";
import DataTable from "../../components/common/DataTable";
import FilterPanel from "../../components/common/FilterPanel";
import { commonHeaderOverrides } from "../../utils/tableColumns";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { useBreadcrumb } from "../../contexts/BreadcrumbContext";

const StudentsList: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate
  const currentLocation = useLocation(); // Rename location to currentLocation
  const { studentsData, loading, error, fetchStudentsData } = useStudents();

  // Dynamic Filter States
  const [filters, setFilters] = useState({
    standard: "",
    section: "",
  });

  const [filteredData, setFilteredData] = useState<any[]>([]); // Holds filtered data
  const [showTable, setShowTable] = useState(false); // Conditional flag for table rendering
  const [searchTerm, setSearchTerm] = useState(""); // Search term state

  const { setBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      { label: "Student Management" },
      {
        label: "Student List",
        path:
          currentLocation.pathname ===
          "/dashboard/student_management/student_list"
            ? ""
            : "/dashboard/student_management/student_list", // Disable link for current page
      }, // No path for the current page
    ]);
  }, [setBreadcrumb, currentLocation.pathname]);

  useEffect(() => {
    // Trigger the API call when this component is mounted
    fetchStudentsData();
  }, [fetchStudentsData]);

  useEffect(() => {
    // Set the initial filtered data to only show 10th class students by default
    const initialFilteredData = studentsData.filter(
      (student) => student.standard.split(" ")[0] === "10th"
    );
    setFilteredData(initialFilteredData);
    setShowTable(true);
  }, [studentsData]);

  if (loading) return <LoadingSpinner message="Loading..." />;
  if (error) return <div>Error: {error}</div>;

  // Handle Search Term Change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Handle Filter Change
  const handleFilterChange = (key: string, value: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  // Handle Filter Submit
  const handleFilterSubmit = () => {
    if (!searchTerm && !filters.standard && !filters.section) {
      alert("Please enter a search term or select a filter.");
      return;
    }

    // First, filter the data based on applied filters
    let filtered = studentsData;

    // Apply filters
    filtered = filtered.filter((student) => {
      return (
        // (!filters.status || student.status === filters.status) &&
        // (!filters.section || student.section === filters.section) &&
        !filters.standard || student.standard.split(" ")[0] === filters.standard
      );
    });

    // Apply search term
    if (searchTerm) {
      filtered = filtered.filter((student) =>
        student.fullName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Set the final filtered data
    setFilteredData(filtered);
    setShowTable(true); // Show the table after applying filters
  };

  // Handle Clear Button Click
  const handleClearSubmit = () => {
    // Clear filters
    setFilters({
      standard: "",
      section: "",
    });

    // Clear search term
    setSearchTerm("");

    // Reset filtered data to only show 10th standard students
    const filtered = studentsData.filter(
      (student) => student.standard.split(" ")[0] === "10th"
    );

    // Optionally, you can also re-fetch the data or reset the search results
    setFilteredData(filtered); // Reset filtered data
    setShowTable(true); // Hide the table if you want
  };

  // Handle Cell Click
  const handleCellClick = (columnKey: string, row: any) => {
    if (columnKey === "id") {
      navigate(
        `/dashboard/student_management/student_list/student_details/${row.id}`
      ); // Navigate to the student details page
    }
  };

  // Dynamic Filter Configuration
  const filterOptions = [
    {
      label: "Standard",
      type: "select" as const,
      options: [
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
      ],
      value: filters.standard,
      onChange: (value: string) => handleFilterChange("standard", value),
    },
    {
      label: "Section",
      type: "select" as const,
      options: ["A", "B", "C", "D"],
      value: filters.section,
      onChange: (value: string) => handleFilterChange("section", value),
    },
  ];

  // Define which columns to display
  const visibleColumns = [
    "id",
    "fullName",
    "standard",
    "gender",
    "phoneNumber",
  ];

  // Custom styles for columns
  const customRenderers = {
    id: (value: number) => (
      <Tippy content="Go to student details" placement="bottom">
        <span className="flex justify-center items-center w-12 h-8 rounded-2xl bg-[#35e6db] transition ease-in-out duration-300 hover:bg-[#02beb2] hover:text-white active:scale-[0.9]">
          {value}
        </span>
      </Tippy>
    ),
  };

  return (
    <div className="bg-slate-100">
      {/* Filter Panel */}
      <FilterPanel
        heading="Student Information"
        filters={filterOptions}
        onFilterSubmit={handleFilterSubmit}
        buttonText="Search Students"
        containerStyle="max-w-full"
        searchTerm={searchTerm} // Pass search term to FilterPanel
        onSearchChange={handleSearchChange} // Handle search term change
        searchBarClassName="w-full"
        placeholder="Search student..."
        onClearSubmit={handleClearSubmit}
      />

      {/* Table Section */}
      {showTable && filteredData.length > 0 && (
        <div className="mt-6">
          <DataTable
            data={filteredData}
            customRenderers={customRenderers}
            headerOverrides={commonHeaderOverrides} // Pass predefined headers
            // onCellClick={(columnKey, row) => console.log(columnKey, row)}
            onCellClick={handleCellClick}
            clickableColumns={["id"]} // Only "name" and "email" columns are clickable
            visibleColumns={visibleColumns} // Pass visible columns to DataTable
          />
        </div>
      )}

      {/* No Data Message */}
      {showTable && filteredData.length === 0 && (
        <div className="mt-6 text-center text-gray-500">No data found</div>
      )}
    </div>
  );
};
export default StudentsList;
