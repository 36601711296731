import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Default styles
import LoginPage from "./pages/LoginPage";
import DashboardLayout from "./components/layout/DashboardLayout";
import DashboardHome from "./pages/dashboard/DashboardHome";
import StudentsList from "./pages/dashboard/StudentsList";
import { DashboardProvider } from "./contexts/DashboardContext";
import { StudentsProvider } from "./contexts/StudentsContext";
import StudentDetailsPage from "./pages/dashboard/StudentDetailsPage";
import { BreadcrumbProvider } from "./contexts/BreadcrumbContext";
import StudentAdmission from "./pages/dashboard/StudentAdmission";
import LogoutPage from "./pages/LogoutPage";
import TokenErrorPage from "./pages/TokenErrorPage";
import HomeSearchStudent from "./pages/dashboard/HomeSearchStudent";
import AdminManagement from "./pages/dashboard/AdminManagement";

const App: React.FC = () => {
  return (
    <StudentsProvider>
      <BreadcrumbProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/session_expired" element={<TokenErrorPage />} />
            <Route
              path="/dashboard/*"
              element={
                <DashboardProvider>
                  <DashboardLayout>
                    <Routes>
                      <Route path="" element={<DashboardHome />} />
                      <Route
                        path="admin_management"
                        element={<AdminManagement />}
                      />
                      <Route
                        path="student_management/student_list"
                        element={<StudentsList />}
                      />
                      <Route
                        path="student_management/student_list/home_search_student"
                        element={<HomeSearchStudent />}
                      />
                      <Route
                        path="student_management/student_list/student_details/:studentId"
                        element={<StudentDetailsPage />}
                      />
                      <Route
                        path="student_management/stu_admiss_form"
                        element={<StudentAdmission />}
                      />
                    </Routes>
                  </DashboardLayout>
                </DashboardProvider>
              }
            />
          </Routes>
        </Router>
      </BreadcrumbProvider>
    </StudentsProvider>
  );
};

export default App;
