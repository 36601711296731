import React, { useState } from "react";
import FormTab from "../../FormTab";
import {
  FieldConfig,
  StudentInfo,
  RowConfig,
} from "../../../../types/FormDataTypes";

export interface StudentInfoTabProps {
  handleNext: (data: any) => void;
  formData: StudentInfo;
}

const StudentInfoTab: React.FC<StudentInfoTabProps> = ({
  handleNext,
  formData,
}) => {
  // Define the fields configuration dynamically, including nested name fields
  const fields: FieldConfig[] = [
    {
      name: "firstName",
      label: "Name",
      type: "text",
      placeholder: "Enter first name",
      required: true,
      validation: ["required", "isAlpha"], // Add validation rules
    },
    {
      name: "middleName",
      type: "text",
      placeholder: "Enter middle name",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "lastName",
      type: "text",
      placeholder: "Enter last name",
      required: true,
      validation: ["required", "isAlpha"],
    },
    {
      name: "dob",
      label: "Date of Birth",
      type: "date",
      required: true,
      validation: ["required", "isDate"],
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      options: ["Male", "Female", "Other"],
      required: true,
      validation: ["required"],
    },
    {
      name: "bloodGroup",
      label: "Blood Group",
      type: "select",
      options: ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"],
      required: false,
    },
    {
      name: "nationality",
      label: "Nationality",
      type: "text",
      placeholder: "Enter nationality",
      required: true,
      validation: ["required", "isAlpha"],
    },
    {
      name: "religion",
      label: "Religion",
      type: "text",
      placeholder: "Enter religion",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "category",
      label: "Category",
      type: "select",
      options: ["SC", "ST", "OC", "OBC", "BC"],
      required: false,
    },
    {
      name: "previousSchoolName",
      label: "Previous School Name",
      type: "text",
      placeholder: "Enter previous school name",
      required: false,
    },
    {
      name: "residentialAddress",
      label: "Residential Address",
      type: "text",
      placeholder: "Enter address",
      required: true,
      validation: ["required"],
    },
    {
      name: "permanentAddress",
      label: "Permanent Address",
      type: "text",
      placeholder: "Enter address",
      required: false,
    },
    {
      name: "emergencyContactAddress",
      label: "Emergency Contact Address",
      type: "text",
      placeholder: "Enter address",
      required: false,
    },
  ];

  const rows: RowConfig[] = [
    { fields: ["firstName", "middleName", "lastName"] },
    { fields: ["dob", "gender", "bloodGroup"] },
    { fields: ["nationality", "religion", "category"] },
    { fields: ["previousSchoolName", "photo"] },
    { fields: ["residentialAddress", "permanentAddress"] },
    { fields: ["emergencyContactAddress"] },
  ];

  return (
    <FormTab
      formData={formData}
      handleNext={handleNext}
      fields={fields}
      rows={rows}
    />
  );
};

export default StudentInfoTab;
