import React from "react";

interface LoadingSpinnerProps {
  message?: string;
  size?: string; // Size as a Tailwind-compatible string (e.g., "h-16 w-16")
  color?: string; // Tailwind color class (e.g., "text-blue-500")
  fullScreen?: boolean; // Optional prop to make the spinner cover the full screen
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  message = "Loading...",
  size = "h-16 w-16", // Default size class
  color = "border-blue-500", // Default color class
  fullScreen = true, // Default to full screen
}) => {
  return (
    <div
      className={`flex justify-center items-center ${
        fullScreen ? "h-screen" : ""
      }`}
    >
      <div className="flex flex-col items-center">
        {/* Spinner with Tailwind classes */}
        <div
          className={`border-t-4 border-solid ${color} rounded-full animate-spin ${size}`}
        ></div>
        {/* Optional message */}
        {message && <p className="mt-5 text-xl">{message}</p>}
      </div>
    </div>
  );
};

export default LoadingSpinner;
