import React, { useState } from "react";
import ProgressBar from "../../ProgressBar";
import StudentInfoTab from "./StudentInfoTab";
import ParentInfoTab from "./ParentInfo";
import AdmissionDetailsTab from "./AdmissionDetailsTab";
import AdditionalInfoTab from "./AdditionalInfoTab";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";

interface FormData {
  studentInfo: any;
  parentInfo: any;
  admissionDetails: any;
  additionalInfo: any;
  [key: string]: any; // Add index signature
}

const AdmissionForm = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState<FormData>({
    studentInfo: {},
    parentInfo: {},
    admissionDetails: {},
    additionalInfo: {},
  });

  const tabTitles = [
    "Student Info",
    "Parent Info",
    "Admission Details",
    "Additional Info",
  ];

  const handleNext = (newData: any, tabIndex: number) => {
    const keys = [
      "studentInfo",
      "parentInfo",
      "admissionDetails",
      "additionalInfo",
    ];
    const currentKey = keys[tabIndex] as keyof FormData;

    setFormData((prev) => ({
      ...prev,
      [currentKey]: newData,
    }));

    if (tabIndex < keys.length - 1) {
      setActiveTab(tabIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
    }
  };

  return (
    <div className="w-full px-6 pt-3 pb-5 bg-[#b8efe53b] shadow-lg rounded-md">
      <h1 className="text-3xl font-bold text-blue-600 mb-6 text-center font-admissionForm">
        Admission Form
      </h1>

      {/* Progress Bar */}
      <ProgressBar
        progress={(activeTab / (tabTitles.length - 1)) * 100}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabTitles={tabTitles}
      />

      {/* Tab Content */}
      <div className="p-8 max-w-6xl h-[24rem] overflow-y-auto mx-auto">
        <div>
          {activeTab === 0 && (
            <StudentInfoTab
              handleNext={(data) => handleNext(data, 0)}
              formData={formData.studentInfo}
            />
          )}
          {activeTab === 1 && (
            <ParentInfoTab
              handleNext={(data) => handleNext(data, 1)}
              formData={formData.parentInfo}
            />
          )}
          {activeTab === 2 && (
            <AdmissionDetailsTab
              handleNext={(data) => handleNext(data, 2)}
              formData={formData.admissionDetails}
            />
          )}
          {activeTab === 3 && (
            <AdditionalInfoTab
              handleNext={(data) => handleNext(data, 3)}
              formData={formData.additionalInfo}
            />
          )}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between items-center mt-6">
        <button
          className={`flex items-center font-semibold gap-x-2 px-7 py-2 rounded-3xl shadow-md ${
            activeTab === 0
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white hover:bg-blue-600"
          } transition-all`}
          onClick={handlePrevious}
          disabled={activeTab === 0}
        >
          <span>
            <IoIosArrowDropleft className="text-xl" />
          </span>
          Previous
        </button>
        <button
          className={`flex items-center font-semibold gap-x-2 px-7 py-2 rounded-3xl shadow-md ${
            activeTab === tabTitles.length - 1
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-green-600 text-white hover:bg-green-700"
          } transition-all`}
          onClick={() =>
            handleNext(formData[Object.keys(formData)[activeTab]], activeTab)
          }
          disabled={activeTab === tabTitles.length - 1}
        >
          Next
          <span>
            <IoIosArrowDropright className="text-xl" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default AdmissionForm;
