import React, { useState } from "react";
import FormTab from "../../FormTab";
import {
  FieldConfig,
  AdditionalInfo,
  RowConfig,
} from "../../../../types/FormDataTypes";

interface AdditionalInfoTabProps {
  handleNext: (data: any) => void;
  formData: AdditionalInfo;
}

const AdditionalInfoTab: React.FC<AdditionalInfoTabProps> = ({
  handleNext,
  formData,
}) => {
  // Define the fields configuration dynamically, including nested name fields
  const fields: FieldConfig[] = [
    {
      name: "hearAboutUs",
      label: "How Did You Hear About Us?",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "commConsent",
      label: "Consent for Communication",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "transportMode",
      label: "Mode of Transport",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },

    {
      name: "bankAccDetails",
      label: "Bank Account Details for Fees Payment",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "comments",
      label: "Additional Notes or Comments",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "medicalConsent",
      label: "Parental Consent for Medical Treatment",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "photoConsent",
      label: "Parental Consent for Photography",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
  ];

  const rows: RowConfig[] = [
    { fields: ["hearAboutUs"] },
    { fields: ["commConsent"] },
    { fields: ["transportMode"] },
    { fields: ["bankAccDetails"] },
    { fields: ["comments"] },
    { fields: ["medicalConsent"] },
    { fields: ["photoConsent"] },
  ];

  return (
    <FormTab
      formData={formData}
      handleNext={handleNext}
      fields={fields}
      rows={rows}
    />
  );
};

export default AdditionalInfoTab;
