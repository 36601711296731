interface tableColumns {
  key: string;
  header: string;
}

/**
 * Generates columns dynamically based on the keys in the provided data.
 * Optionally allows overriding headers for specific keys.
 * @param data Array of objects representing rows.
 * @param visibleColumns Array of column keys in the desired order.
 * @param headerOverrides Optional object to override column headers.
 */
export const generateColumns = (
  data: any[],
  visibleColumns: string[],
  headerOverrides: { [key: string]: string } = {}
): tableColumns[] => {
  if (data.length === 0) return [];

  // Generate columns with default or overridden headers
  const columns = Object.keys(data[0]).map((key) => ({
    key,
    header: headerOverrides[key] || key.charAt(0).toUpperCase() + key.slice(1),
  }));

  // Reorder the columns based on visibleColumns
  const orderedColumns = columns
    .filter((col) => visibleColumns.includes(col.key))
    .sort(
      (a, b) => visibleColumns.indexOf(a.key) - visibleColumns.indexOf(b.key)
    );

  return orderedColumns;
};

// Predefined common header overrides (optional)
export const commonHeaderOverrides = {
  id: "ID",
  firstName: "First Name",
  lastName: "Last Name",
  fullName: "Full Name",
  email: "Email",
  phoneNumber: "Phone Number",
  address: "Address",
  status: "Status",
};
