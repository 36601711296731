import React, { useState } from "react";
import { FaUsers, FaChalkboardTeacher, FaRegCreditCard } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import {
  IoIosArrowForward,
  IoIosArrowDown,
  IoMdSettings,
} from "react-icons/io"; // Add icons for dropdown
import { HiUsers } from "react-icons/hi2";
import { TbTools } from "react-icons/tb";
import { SlBookOpen } from "react-icons/sl";
import { BsCalculatorFill } from "react-icons/bs";
import { NavLink, useLocation } from "react-router-dom";
import { useDashboard } from "../../contexts/DashboardContext";
import Tippy from "@tippyjs/react";
import { getMenuTabs } from "../../utils/loginSecureUtility";
import {
  BreadcrumbItem,
  useBreadcrumb,
} from "../../contexts/BreadcrumbContext";

// Define types for menu items
interface DropdownItem {
  path: string;
  label: string;
}

interface NavItem {
  path: string;
  icon: JSX.Element | null;
  label: string;
  tooltip: string;
  dropdown?: DropdownItem[];
}

// Type the return value of getMenuTabs() to avoid 'unknown'
type MenuTabs = {
  [key: string]: string[];
};

const Sidebar: React.FC = () => {
  const { isSidebarOpen } = useDashboard();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null); // Track the open dropdown label
  const location = useLocation(); // Get the current location (path)

  const menuTabItems: MenuTabs = getMenuTabs(); // Assuming getMenuTabs() returns the correct structure

  const toggleDropdown = (label: string) => {
    setOpenDropdown((prev) => (prev === label ? null : label)); // Toggle the current dropdown
  };

  // const closeDropdowns = () => {
  //   setOpenDropdown(null); // Close all dropdowns
  // };

  const { setBreadcrumb } = useBreadcrumb();

  // Update breadcrumb for dropdown items
  const handleBreadcrumbUpdate = (
    mainLabel: string,
    subLabel?: string,
    path?: string
  ) => {
    const breadcrumb: BreadcrumbItem[] = [{ label: mainLabel }];
    if (subLabel && path) {
      breadcrumb.push({ label: subLabel, path });
    }
    setBreadcrumb(breadcrumb);
  };

  // Extract navItems from the menuTabItems object
  const navItems: NavItem[] = Object.entries(menuTabItems).map(
    ([key, dropdownItems]) => {
      const [keyName, label] = key.split(";");
      const basePath = keyName.toLowerCase().replace(/\s+/g, "-"); // base path for main items

      // console.log("basepath: ", basePath);

      return {
        path: keyName === "DASHBOARD" ? "/dashboard" : `/dashboard/${basePath}`, // Main nav item path
        icon:
          keyName === "DASHBOARD" ? (
            <MdOutlineDashboard className="text-xl" />
          ) : keyName === "ADMIN_MANAGEMENT" ? (
            <HiUsers />
          ) : keyName === "STUDENT_MANAGEMENT" ? (
            <FaUsers />
          ) : keyName === "STAFF_MANAGEMENT" ? (
            <FaChalkboardTeacher />
          ) : keyName === "CLASSES" ? (
            <TbTools />
          ) : keyName === "SUBJECTS" ? (
            <SlBookOpen />
          ) : keyName === "FEE_PAYMENT" ? (
            <FaRegCreditCard />
          ) : keyName === "ACCOUNTING" ? (
            <BsCalculatorFill />
          ) : keyName === "ADMIN_CONFIG" ? (
            <IoMdSettings />
          ) : null,
        label,
        tooltip: keyName === "DASHBOARD" ? "Go To Dashboard" : `${label}`,
        dropdown:
          dropdownItems.length > 0
            ? dropdownItems.map((item) => {
                const [itemKey, itemLabel] = item.split(";");
                const subPath = `${basePath}/${itemKey
                  .toLowerCase()
                  .replace(/\s+/g, "-")}`; // Append the dropdown path

                // console.log("subPath:", subPath);

                return { path: `/dashboard/${subPath}`, label: itemLabel };
              })
            : undefined,
      };
    }
  );

  return (
    <aside
      className={`left-0 z-[1030] ${isSidebarOpen ? "fixed" : "relative"} `}
    >
      <div
        className={`bg-[#1d4e89] text-white flex flex-col transition-all duration-300 pb-5 ${
          isSidebarOpen
            ? "w-48 justify-between h-screen"
            : "w-20 justify-center h-full"
        } transition-transform transform z-[1001] overflow-visible`}
      >
        {/* Scrollable Content Wrapper */}
        <div
          className={`flex-1 ${
            isSidebarOpen ? "overflow-auto scrollbar" : "overflow-visible"
          }`}
        >
          <ul className="pt-4 pb-36 space-y-4 px-2">
            {navItems.map((item, index) => (
              <li key={index} className="relative group">
                {/* Tooltip */}
                <Tippy
                  content={item.tooltip}
                  placement="right"
                  arrow={true}
                  className={`${
                    isSidebarOpen
                      ? "hidden"
                      : item.dropdown
                      ? "hidden"
                      : "block"
                  }`}
                >
                  {!item.dropdown ? (
                    <NavLink
                      to={!item.dropdown ? item.path : ""} // Remove "to" when it's a dropdown
                      onClick={() => {
                        handleBreadcrumbUpdate(
                          item.label,
                          undefined,
                          item.path
                        );
                        setOpenDropdown(null); // Close all dropdowns
                      }}
                      className={() => {
                        const isSelected = location.pathname === item.path;
                        return `flex items-center p-2 rounded hover:bg-[#9e9e9ec2] ${
                          isSelected ? "bg-[#8d99ae]" : ""
                        } ${isSidebarOpen ? "gap-x-3" : "justify-center"}`;
                      }}
                    >
                      <span className="text-2xl">{item.icon}</span>
                      <span
                        className={`font-sidebar text-sm  transition-opacity ${
                          isSidebarOpen ? "block" : "hidden"
                        }`}
                      >
                        {item.label}
                      </span>
                    </NavLink>
                  ) : (
                    <a
                      onClick={() => {
                        toggleDropdown(item.label); // Toggle dropdown
                      }}
                      className={`flex items-center p-2 rounded hover:bg-[#9e9e9ec2] ${
                        isSidebarOpen ? "gap-x-3" : "justify-center"
                      }`}
                    >
                      <span className="text-xl">{item.icon}</span>
                      <span
                        className={`font-sidebar text-sm ${
                          isSidebarOpen ? "block" : "hidden"
                        }`}
                      >
                        {item.label}
                      </span>
                      {isSidebarOpen && (
                        <span className="ml-auto text-xl">
                          {openDropdown === item.label ? (
                            <IoIosArrowDown />
                          ) : (
                            <IoIosArrowForward />
                          )}
                        </span>
                      )}
                    </a>
                  )}
                </Tippy>

                {/* Dropdown Menu */}
                {item.dropdown && (
                  <ul
                    className={`pl-8 mt-1 space-y-1 transition-all duration-300 ease-out max-h-0 overflow-hidden ${
                      openDropdown === item.label && isSidebarOpen
                        ? "max-h-[500px] opacity-100" // Smoothly show the dropdown
                        : "opacity-0"
                    }`}
                  >
                    {item.dropdown.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <NavLink
                          to={subItem.path}
                          className={() => {
                            const isSelected =
                              location.pathname === subItem.path;
                            return `font-sidebar text-xs block p-2 rounded hover:bg-[#9e9e9ec2] ${
                              isSelected ? "bg-[#8d99ae]" : ""
                            }`;
                          }}
                        >
                          {subItem.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}

                {/* Hover-based dropdown for collapsed sidebar */}
                {item.dropdown && !isSidebarOpen && (
                  <div
                    className="absolute left-full top-0 hidden group-hover:block bg-[#1d4e89] rounded-md z-[1050]"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      width: "max-content", // Ensure dropdown width adjusts based on content
                    }}
                  >
                    <ul className="p-2">
                      {item.dropdown.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <NavLink
                            to={subItem.path}
                            className="block text-sm font-sidebar px-2 py-2 hover:bg-[#9e9e9ec2] rounded"
                          >
                            {subItem.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
