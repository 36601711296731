import React, { createContext, useContext, useState } from "react";

// Define the context type
interface DashboardContextProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context
const DashboardContext = createContext<DashboardContextProps | undefined>(
  undefined
);

// Custom hook for accessing the context
export const useDashboard = (): DashboardContextProps => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboard must be used within DashboardProvider");
  }
  return context;
};

// Create a provider component
export const DashboardProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <DashboardContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
      {children}
    </DashboardContext.Provider>
  );
};
