import React, { useEffect, useRef, useState } from "react";
import { FaEnvelope, FaRegUser, FaSearch } from "react-icons/fa"; // Add FaSearch
import { TbArrowBigRightFilled, TbLogout } from "react-icons/tb";
import { TiArrowSortedDown } from "react-icons/ti";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useDashboard } from "../../contexts/DashboardContext";
import Tippy from "@tippyjs/react";
import Popup from "./Popup";
import { getCampuses, getSessionData } from "../../utils/loginSecureUtility";
import { CgProfile } from "react-icons/cg";
import { MdOutlineSettings } from "react-icons/md";
import { useDropdown } from "../../hooks/useDropdown";
import school_logo from "../../assets/images/school_logo.png";
import LoadingSpinner from "./LoadingSpinner";
import HomeSearch from "./HomeSearch";

const Header: React.FC = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useDashboard();
  const [selectedCampus, setSelectedCampus] = useState<{
    campusName: string;
    campusId: number;
  } | null>(null);
  const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const [isCampusSwitchPopupOpen, setCampusSwitchPopupOpen] = useState(false);
  const [newCampus, setNewCampus] = useState<{
    campusName: string;
    campusId: number;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false); // New state to toggle the search visibility
  const headerRef = useRef<HTMLDivElement>(null); // Reference to header for detecting clicks outside

  const campusDropdown = useDropdown();
  const profileDropdown = useDropdown();

  const navigate = useNavigate();

  // Use campuses from sessionStorage via getCampuses
  const campusOptions = getCampuses();

  // Set the selected campus when campusOptions is loaded or updated
  useEffect(() => {
    const storedCampusId = sessionStorage.getItem("selectedCampusId");
    if (campusOptions.length > 0 && !selectedCampus && storedCampusId) {
      const defaultCampus = campusOptions.find(
        (campus) => campus.campusId === parseInt(storedCampusId)
      );
      if (defaultCampus) {
        setSelectedCampus(defaultCampus); // Set the default campus if found
      }
    }
  }, [campusOptions, selectedCampus]);

  // Function to handle campus selection from dropdown
  const handleCampusSelect = (campus: {
    campusName: string;
    campusId: number;
  }) => {
    if (selectedCampus?.campusId === campus.campusId) {
      campusDropdown.closeDropdown();
      return;
    }

    setNewCampus(campus); // Store the new campus to show in the popup
    setCampusSwitchPopupOpen(true); // Show the popup

    // setSelectedCampus(campus); // Update selected campus in state
    // sessionStorage.setItem("selectedCampusId", campus.campusId.toString()); // Save the selected campus ID in sessionStorage
    campusDropdown.closeDropdown(); // Close the dropdown
  };

  const handleSwitchCampus = () => {
    if (newCampus) {
      setIsLoading(true); // Set loading to true to show the spinner
      setSelectedCampus(newCampus);
      sessionStorage.setItem("selectedCampusId", newCampus.campusId.toString());
      setCampusSwitchPopupOpen(false);
      navigate("/dashboard"); // Redirect to dashboard
      window.location.reload(); // Refresh the page to clear all fields
    }
  };

  const handleCancelSwitchCampus = () => {
    setCampusSwitchPopupOpen(false);
    setNewCampus(null);
  };

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible); // Toggle search visibility
  };

  const closeSearch = () => {
    setIsSearchVisible(false); // Close search when clicking outside or on the close button
  };

  // Close search if clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target as Node)
      ) {
        setIsSearchVisible(false); // Close search if clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Define menu items in a single array
  const menuItems = [
    {
      label: "Messages",
      icon: (
        <FaEnvelope className="text-gray-600 cursor-pointer hover:text-blue-500" />
      ),
      tooltip: "Messages",
    },
  ];

  // Get the user's name from sessionStorage
  const { userName } = getSessionData();

  // Define profile items in a single array
  const profileItems = [
    {
      label: (
        <>
          <CgProfile /> My Profile
        </>
      ),
    },
    {
      label: (
        <>
          <MdOutlineSettings /> Account Settings
        </>
      ),
    },
  ];

  const handleLogout = () => {
    // Clear all sessionStorage data
    sessionStorage.clear();
    navigate("/logout");
  };

  return (
    <header
      ref={headerRef}
      className="fixed top-0 bg-[#e0e2db] z-[1031] w-full"
    >
      <nav className={`flex items-center justify-normal h-16`}>
        <div
          className={`grid bg-[#1d4e89] h-full text-white px-4 transition-all duration-300 ${
            isSidebarOpen ? `w-48` : `w-20`
          } transition-transform transform `}
        >
          <div className={`${!isSidebarOpen && "justify-self-center"}`}>
            <br />
          </div>
          {isSidebarOpen ? (
            <>
              <Button
                label={<MdKeyboardDoubleArrowLeft size={30} />}
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="focus:outline-none justify-self-end"
              />
            </>
          ) : (
            <Button
              label={<MdKeyboardDoubleArrowRight size={30} />}
              className="focus:outline-none justify-self-center"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            />
          )}
        </div>

        <div
          className={`flex flex-1 items-center justify-center h-full px-3 xl:px-5 bg-[#e0e2db] border-b-2 transition-all duration-300 ${
            isSearchVisible ? "translate-y-0" : "-translate-y-full"
          } ${
            isSidebarOpen ? "left-48" : "left-20"
          }  transition-transform transform absolute top-0 right-0 z-[1032] `}
        >
          <HomeSearch />
          <Button
            label="&times;"
            onClick={closeSearch}
            className="absolute right-5 top-4 text-2xl font-bold hover:text-red-600"
          />
        </div>

        {!isSearchVisible && (
          <div className="flex flex-1 items-center h-full px-3 xl:px-5 bg-[#e0e2db] border-b-2">
            <ul className={`flex flex-1 items-center gap-x-2 xl:gap-x-3`}>
              <img src={school_logo} alt="school logo" width={50} height={60} />

              <div className="text-lg xl:text-xl max-w-[200px] xl:max-w-[25rem] bg-[#eb8ba8] rounded-3xl px-3 py-1 truncate text-center">
                {sessionStorage.getItem("tenantName")}
              </div>
              <div className="relative">
                <Tippy
                  content="Select a campus"
                  placement="bottom"
                  className={`${
                    campusDropdown.isDropdownOpen ? "invisible" : "visible"
                  }`}
                >
                  <Button
                    label={
                      <>
                        <span className="truncate">
                          {selectedCampus
                            ? selectedCampus.campusName
                            : "Select Campus"}
                        </span>
                        <TiArrowSortedDown />
                      </>
                    }
                    onClick={campusDropdown.toggleDropdown}
                    className="bg-[#07beb8] w-36 xl:w-44 py-1 px-3 rounded-3xl text-white focus:outline-none flex justify-between items-center gap-x-1 truncate"
                  />
                </Tippy>
                {campusDropdown.isDropdownOpen && (
                  <div
                    ref={campusDropdown.dropdownRef}
                    className="absolute mt-2 w-44 bg-white shadow-lg rounded-md z-10"
                  >
                    {campusOptions.map((campus, index) => (
                      <div
                        key={index}
                        onClick={() => handleCampusSelect(campus)}
                        className={`p-2 cursor-pointer ${
                          selectedCampus &&
                          selectedCampus.campusId === campus.campusId
                            ? "bg-blue-500 text-white"
                            : "hover:bg-gray-200"
                        }`}
                      >
                        {campus.campusName}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <Tippy content="Click to search" placement="bottom">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    onClick={toggleSearch}
                    value={sessionStorage.getItem("searchTerm") || ""}
                    readOnly
                    className="rounded-2xl ps-4 pe-9 py-1 w-36 xl:w-44 focus:outline-none truncate"
                  />
                  <Button
                    label={<FaSearch />}
                    onClick={toggleSearch} // Show the search
                    className="absolute top-2 right-4 text-black font-bold hover:text-red-600"
                  />
                </div>
              </Tippy>
            </ul>

            <ul className={`flex items-center gap-x-5 xl:gap-x-7`}>
              <ul className="flex items-center gap-x-4 xl:gap-x-7">
                {menuItems.map((item, index) => (
                  <li key={index} className="relative flex items-center">
                    {/* Tooltip */}
                    <Tippy
                      content={item.tooltip}
                      placement="bottom"
                      arrow={true}
                    >
                      <div
                        data-tooltip-id={`menu-tooltip-${index}`} // Unique ID for each tooltip
                        data-tooltip-content={item.tooltip} // Tooltip content
                        className="cursor-pointer"
                        data-tooltip-place="bottom"
                      >
                        {item.icon}
                      </div>
                    </Tippy>
                  </li>
                ))}
              </ul>

              <div className="relative">
                <Button
                  label={
                    <>
                      <FaRegUser />
                      <span className="font-semibold truncate max-w-12">
                        {userName}
                      </span>
                    </>
                  }
                  className="flex items-center gap-x-1"
                  onClick={profileDropdown.toggleDropdown}
                />
                {profileDropdown.isDropdownOpen && (
                  <div
                    ref={profileDropdown.dropdownRef}
                    className="absolute -left-36 w-48 flex flex-col mt-2 bg-white shadow-lg rounded-md z-10"
                  >
                    <li className="text-center py-2 bg-blue-500 text-white rounded-t">
                      {userName}
                    </li>
                    {profileItems.map((item, index) => (
                      <li
                        key={index}
                        className="flex items-center gap-x-2 p-2 cursor-pointer hover:bg-gray-200"
                      >
                        {item.label}
                      </li>
                    ))}
                  </div>
                )}
              </div>

              <Button
                label={
                  <>
                    Log Out <TbLogout />
                  </>
                }
                onClick={() => setLogoutPopupOpen(true)}
                className="flex items-center gap-x-2 text-black font-bold hover:text-red-600"
              />
            </ul>
          </div>
        )}
      </nav>

      {/* Campus Switch Confirmation Popup */}
      <Popup
        isPopupOpen={isCampusSwitchPopupOpen}
        title="Switch Campus"
        divClassName="bg-white py-8 px-6 w-[40rem]"
        content={
          <>
            <p className="mb-5 text-red-500 font-bold text-lg">
              Switching campus will clear all your unsaved changes. Please save
              your changes before switching.
            </p>
            <div className="flex justify-center gap-x-5 text-sm font-bold items-center mb-8">
              <span className="py-2 px-5 bg-blue-400 rounded-2xl">
                {selectedCampus?.campusName}
              </span>

              <Button
                label={
                  <>
                    <TbArrowBigRightFilled size={28} />
                  </>
                }
                onClick={() => handleSwitchCampus()}
              />

              <span className="py-2 px-5 bg-green-400 rounded-2xl">
                {newCampus?.campusName}
              </span>
            </div>
          </>
        }
        buttons={[
          {
            label: "Don't switch",
            onClick: handleCancelSwitchCampus,
            style: "bg-gray-300 hover:bg-gray-400 ml-auto",
          },
        ]}
        onClose={handleCancelSwitchCampus}
      />

      {/* Logout Confirmation Popup */}
      <Popup
        isPopupOpen={isLogoutPopupOpen}
        title="Confirm Logout"
        divClassName="bg-[#f8edeb] min-w-[28rem]"
        content={
          <p className="text-lg text-center mt-4 mb-5">
            Are you sure you want to log out?
          </p>
        }
        buttons={[
          {
            label: "Logout",
            onClick: handleLogout,
            style: "bg-[#517cad] text-white hover:bg-[#345e8d]",
          },
          {
            label: "Stay",
            onClick: () => setLogoutPopupOpen(false),
            style: "bg-[#f589a4] text-white hover:bg-[#e86f8d]",
          },
        ]}
        onClose={() => setLogoutPopupOpen(false)}
      />

      {/* Show loading spinner if isLoading is true */}
      {isLoading && (
        <LoadingSpinner
          message={`Switching to ${newCampus?.campusName} and redirecting to dashboard...`}
          fullScreen={true}
        />
      )}
    </header>
  );
};

export default Header;
