import React, { useEffect, useState } from "react";
import { homeSearchResults } from "../../services/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useBreadcrumb } from "../../contexts/BreadcrumbContext";
import Tippy from "@tippyjs/react";
import FilterPanel from "../../components/common/FilterPanel";
import DataTable from "../../components/common/DataTable";
import { commonHeaderOverrides } from "../../utils/tableColumns";

const HomeSearchStudent: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate
  const currentLocation = useLocation(); // Rename location to currentLocation

  // Dynamic Filter States
  const [filters, setFilters] = useState({
    standard: "",
    section: "",
  });

  const [searchedData, setSearchedData] = useState<any[]>([]);
  const [showTable, setShowTable] = useState(false); // Conditional flag for table rendering
  const [displayData, setDisplayData] = useState<any[]>([]);

  const { setBreadcrumb } = useBreadcrumb();

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const searchType = searchParams.get("searchType") || "";

  useEffect(() => {
    setBreadcrumb([
      { label: "Student Management" },
      {
        label: "Student List",
        path: "/dashboard/student_management/student_list",
      },
      {
        label: "Searched Students",
        path:
          currentLocation.pathname ===
          "/dashboard/student_management/student_list/home_search_student"
            ? ""
            : "/dashboard/student_management/student_list/home_search_student", // Disable link for current page
      }, // No path for the current page
    ]);
  }, [setBreadcrumb, currentLocation.pathname]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await homeSearchResults({ search, searchType });

        if (response) {
          setSearchedData(response);
          setDisplayData(response);
          setShowTable(true);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
      }
    };

    if (search && searchType) {
      fetchResults();
    }
  }, [search, searchType]);

  // Handle Filter Change
  const handleFilterChange = (key: string, value: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  // Handle Filter Submit
  const handleFilterSubmit = () => {
    // First, filter the data based on applied filters
    let filtered = displayData;

    // Apply filters
    filtered = filtered.filter((student) => {
      return (
        // (!filters.section || student.section === filters.section) &&
        !filters.standard || student.standard.split(" ")[0] === filters.standard
      );
    });

    // Set the final filtered data
    setDisplayData(filtered);
    setShowTable(true); // Show the table after applying filters
  };

  // Handle Clear Button Click
  const handleClearSubmit = () => {
    // Clear filters
    setFilters({
      standard: "",
      section: "",
    });

    // Optionally, you can also re-fetch the data or reset the search results
    setDisplayData(searchedData); // Reset filtered data
    setShowTable(true); // Hide the table if you want
  };

  // Handle Cell Click
  const handleCellClick = (columnKey: string, row: any) => {
    if (columnKey === "id") {
      navigate(
        `/dashboard/student_management/student_list/student_details/${row.id}`
      ); // Navigate to the student details page
    }
  };

  // Dynamic Filter Configuration
  const filterOptions = [
    {
      label: "Standard",
      type: "select" as const,
      options: ["1st", "2nd", "3rd", "4th", "5th"],
      value: filters.standard,
      onChange: (value: string) => handleFilterChange("standard", value),
    },
    {
      label: "Section",
      type: "select" as const,
      options: ["A", "B", "C", "D"],
      value: filters.section,
      onChange: (value: string) => handleFilterChange("section", value),
    },
  ];

  // Define which columns to display
  const visibleColumns = [
    "id",
    "fullName",
    "standard",
    "gender",
    "phoneNumber",
  ];

  // Custom styles for columns
  const customRenderers = {
    id: (value: number) => (
      <Tippy content="Go to student details" placement="bottom">
        <span className="flex justify-center items-center w-12 h-8 rounded-2xl bg-[#35e6db] transition ease-in-out duration-300 hover:bg-[#02beb2] hover:text-white active:scale-[0.9]">
          {value}
        </span>
      </Tippy>
    ),
  };

  return (
    <div className="bg-slate-100">
      {/* Filter Panel */}
      <FilterPanel
        heading="Student Information"
        filters={filterOptions}
        onFilterSubmit={handleFilterSubmit}
        buttonText="Filter Students"
        containerStyle="max-w-full"
        showSearch={false}
        onClearSubmit={handleClearSubmit}
      />

      {/* Table Section */}
      {showTable && displayData.length > 0 && (
        <div className="mt-6">
          <DataTable
            data={displayData}
            customRenderers={customRenderers}
            headerOverrides={commonHeaderOverrides} // Pass predefined headers
            // onCellClick={(columnKey, row) => console.log(columnKey, row)}
            onCellClick={handleCellClick}
            clickableColumns={["id"]}
            visibleColumns={visibleColumns} // Pass visible columns to DataTable
            fixedHeight={true}
          />
        </div>
      )}

      {/* No Data Message */}
      {showTable && displayData.length === 0 && (
        <div className="mt-6 text-center text-gray-500">No data found</div>
      )}
    </div>
  );
};

export default HomeSearchStudent;
