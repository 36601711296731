import React, { useState } from "react";
import FormTab from "../../FormTab";
import {
  FieldConfig,
  AdmissionDetails,
  RowConfig,
} from "../../../../types/FormDataTypes";

interface AdmissionDetailsTabProps {
  handleNext: (data: any) => void;
  formData: AdmissionDetails;
}

const AdmissionDetailsTab: React.FC<AdmissionDetailsTabProps> = ({
  handleNext,
  formData,
}) => {
  // Define the fields configuration dynamically, including nested name fields
  const fields: FieldConfig[] = [
    {
      name: "grade",
      label: "Grade",
      type: "number",
      placeholder: "Enter grade/class applied for",
      required: true,
      validation: ["required"],
    },
    {
      name: "admissionDate",
      label: "Admission Date",
      type: "date",
      required: true,
      validation: ["required"],
    },
    {
      name: "admissionMode",
      label: "Mode of Admission",
      type: "text",
      placeholder: "Enter mode",
      required: true,
      validation: ["required", "isAlpha"],
    },
    {
      name: "admissionReason",
      label: "Reason for Admission",
      type: "text",
      placeholder: "Enter reason",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "medicalInfo",
      label: "Medical History",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "medicalContact",
      label: "Emergency Medical Contact",
      type: "tel",
      placeholder: "Enter contact",
      required: true,
      validation: ["required", "isPhoneNumber"],
    },
    {
      name: "doctorName",
      label: "Doctor's Name",
      type: "text",
      placeholder: "Enter name",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "doctorContact",
      label: "Doctor's Contact",
      type: "tel",
      placeholder: "Enter contact",
      required: false,
      validation: ["isPhoneNumber"],
    },

    {
      name: "academicInfo",
      label: "Previous Academic Performance",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "specialInterest",
      label: "Special Talents or Interests",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
    {
      name: "parentEduLevel",
      label: "Parent's Education Level",
      type: "text",
      required: false,
      validation: ["isAlpha"],
    },
  ];

  const rows: RowConfig[] = [
    { fields: ["grade"] },
    { fields: ["admissionDate", "admissionMode", "admissionReason"] },
    { fields: ["medicalInfo", "medicalContact"] },
    { fields: ["doctorName", "doctorContact"] },
    { fields: ["academicInfo", "specialInterest"] },
    { fields: ["parentEduLevel"] },
  ];

  return (
    <FormTab
      formData={formData}
      handleNext={handleNext}
      fields={fields}
      rows={rows}
    />
  );
};

export default AdmissionDetailsTab;
