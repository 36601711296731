// Utility function to get the current authToken
export const getAuthToken = () => {
  return sessionStorage.getItem("authToken");
};

// Utility function to get session data (tenant info, user info, etc.)
export const getSessionData = () => {
  const userName = sessionStorage.getItem("userName");
  const tenantId = sessionStorage.getItem("tenantId");
  const tenantName = sessionStorage.getItem("tenantName");

  return { userName, tenantId, tenantName };
};

// Utility function to get campuses from sessionStorage
export const getCampuses = (): {
  campusName: string;
  campusId: number;
  isDefault: string;
}[] => {
  const campusesData = JSON.parse(sessionStorage.getItem("campuses") || "[]");

  const defaultCampus = campusesData.find(
    (campus: { isDefault: string }) => campus.isDefault === "Y"
  );

  const sortedCampuses = campusesData.filter(
    (campus: { isDefault: string }) => campus.isDefault !== "Y"
  );

  if (defaultCampus) {
    // Set the default campus ID in sessionStorage if not already set
    if (!sessionStorage.getItem("selectedCampusId")) {
      sessionStorage.setItem(
        "selectedCampusId",
        defaultCampus.campusId.toString()
      );
      console.log("Campus ID set in sessionStorage:", defaultCampus.campusId);
    }

    return [defaultCampus, ...sortedCampuses];
  }

  // In case there's no default campus, just return the campuses data
  return campusesData;
};

// Utility function to get menu tabs from sessionStorage
// Define the type for the structure of menuTabsData
export const getMenuTabs = (): Record<string, string[]> => {
  const menuTabsData = sessionStorage.getItem("menuTabs");

  // Check if data exists, and parse it into an object if valid
  if (menuTabsData) {
    try {
      return JSON.parse(menuTabsData); // Parse the stored JSON string into an object
    } catch (e) {
      console.error("Error parsing menuTabs data:", e);
      return {}; // Return an empty object if parsing fails
    }
  }

  return {}; // If no menuTabs in sessionStorage, return an empty object
};
