import React, { useState } from "react";

// Define the type for the state object
interface StudentInfo {
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  gender: string;
  otherGender: string; // Add the field to store the value of "other" gender
  nationality: string;
  religion: string;
  bloodGroup: string;
  category: string;
  previousSchoolName: string;
}

interface FieldConfig {
  name: string;
  label: string;
  type?: string | "select" | "date"; // Make type optional
  options?: string[]; // Only needed for select fields
  subItem?: { placeholder: string; type: string }[]; // For complex types like "name"
  required?: boolean; // Mark field as required
  placeholder?: string;
}

interface StudentInfoTabProps {
  handleNext: (data: any) => void;
  formData: StudentInfo;
}

const StudentInfoTab: React.FC<StudentInfoTabProps> = ({
  handleNext,
  formData,
}) => {
  const [studentInfo, setStudentInfo] = useState<StudentInfo>(formData);

  // Define the fields configuration dynamically, including nested name fields
  const fields: FieldConfig[] = [
    {
      name: "name",
      label: "Name",
      subItem: [
        { placeholder: "Enter first name", type: "text" },
        { placeholder: "Enter middle name", type: "text" },
        { placeholder: "Enter last name", type: "text" },
      ],
      required: true, // Mark name as required
    },
    {
      name: "dob",
      label: "Date of Birth",
      type: "date",
      required: true,
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      options: ["Male", "Female", "Other"],
      required: true,
    },
    {
      name: "nationality",
      label: "Nationality",
      type: "text",
      placeholder: "Enter nationality",
      required: true,
    },
    {
      name: "religion",
      label: "Religion",
      type: "text",
      placeholder: "Enter religion",
      required: false,
    },
    {
      name: "bloodGroup",
      label: "Blood Group",
      type: "select",
      options: ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"],
      required: false,
    },
    {
      name: "category",
      label: "Category",
      type: "select",
      options: ["SC", "ST", "OC", "OBC", "BC"],
      required: false,
    },
    {
      name: "previousSchool",
      label: "Previous School Name",
      type: "text",
      placeholder: "Enter previous school name",
      required: false,
    },
  ];

  // Update handleChange to work with both input and select elements
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setStudentInfo((prev: StudentInfo) => ({
      ...prev,
      [name]: value, // TypeScript now understands that `name` is a key of `StudentInfo`
    }));
  };

  const handleSubmit = () => {
    handleNext(studentInfo);
  };

  return (
    <div>
      <form className="space-y-4">
        {/* Render fields dynamically */}
        <div className="grid gap-4">
          {fields.map((field) => {
            if (field.subItem) {
              // Handle "name" field or other complex fields with subItems
              return (
                <div key={field.name} className="col-span-2">
                  <label className="block font-semibold font-admissionForm mb-1">
                    {field.label}
                  </label>
                  <div className="flex gap-x-3">
                    {field.subItem.map((subField, index) => (
                      <div key={index}>
                        <input
                          type={subField.type}
                          name={subField.placeholder} // Use the placeholder as the key
                          value={
                            studentInfo[
                              subField.placeholder as keyof StudentInfo
                            ] || ""
                          }
                          onChange={handleChange}
                          className="input border-solid border-2 border-slate-200 rounded-sm px-2"
                          placeholder={subField.placeholder}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            }

            // Handle normal fields (dob, gender, age, etc.)
            return (
              <div key={field.name} className="col-span-1">
                <label className="block font-semibold font-admissionForm mb-1">
                  {field.label}
                </label>
                {field.name === "gender" ? (
                  <div className="flex gap-2">
                    <select
                      name={field.name}
                      value={studentInfo[field.name as keyof StudentInfo] || ""}
                      onChange={handleChange}
                      className="input px-2 py-1 border-solid border-2 border-slate-200 rounded-sm"
                    >
                      <option value="">Select</option>
                      {field.options?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {studentInfo.gender === "Other" && (
                      <input
                        type="text"
                        name="otherGender"
                        value={studentInfo.otherGender || ""}
                        onChange={handleChange}
                        className="input border-solid border-2 border-slate-200 rounded-sm px-2"
                        placeholder="Please specify"
                      />
                    )}
                  </div>
                ) : field.type === "select" ? (
                  <select
                    name={field.name}
                    value={studentInfo[field.name as keyof StudentInfo] || ""}
                    onChange={handleChange}
                    className="input px-2 py-1 border-solid border-2 border-slate-200 rounded-sm"
                  >
                    <option value="">Select</option>
                    {field.options?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    value={studentInfo[field.name as keyof StudentInfo] || ""}
                    onChange={handleChange}
                    className="input border-solid border-2 border-slate-200 rounded-sm px-2"
                    placeholder={field.placeholder}
                  />
                )}
              </div>
            );
          })}
        </div>
      </form>
    </div>
  );
};

export default StudentInfoTab;
